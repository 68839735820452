

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Song',
    props: {
        nomeMusica: String,
        spotify: String,
        youtube: String,
    }
});
