

import { defineComponent } from 'vue';
import Song from '../components/Song.vue'

export default defineComponent({
    name: 'SongCard',
    components: {
        Song,
    },
    props: {
        nomeCD: String,
        musicas: {
            type: Array,
            required: true,
        },
        imgPath: String,
    }
});
